import React from 'react'
import { RowWrapper } from '~/components/Cart/Summary'
import { Button, Text } from 'theme-ui'
import { useCart } from '@chordcommerce/gatsby-theme-performance'

const AppliedGiftCards = () => {
  const { cart, removeGiftCard } = useCart()
  const appliedGiftCards = cart.data.appliedGiftCards || []

  return (
    <>
      {appliedGiftCards.map((appliedGiftCard, i) => {
        const {
          id: appliedGiftCardId,
          amountUsedV2: { amount: amountUsed },
          lastCharacters
        } = appliedGiftCard
        return (
          <RowWrapper key={appliedGiftCardId}>
            <Text>Gift Card (ending in {lastCharacters})</Text>
            <Button
              sx={{
                padding: '1px 12px'
              }}
              onClick={() => removeGiftCard(appliedGiftCard)}
            >
              Remove
            </Button>
            <Text>-${(+amountUsed).toFixed(2)}</Text>
          </RowWrapper>
        )
      })}
    </>
  )
}

export default AppliedGiftCards
