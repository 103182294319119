/** @jsx jsx */
import { useAnalytics } from '@chordcommerce/gatsby-theme-performance'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Box, Flex, jsx, Text, Image } from 'theme-ui'
import QuantitySelector from '~/components/Cart/QuantitySelector'
import useProductMainImage from '~/hooks/components/use-product-main-image'

const LineItem = ({
  id,
  sku,
  variantImage,
  slug,
  name,
  options,
  quantity,
  displayAmount,
  singleDisplayAmount,
  orderConfirmed = false
}) => {
  const { trackProductClicked } = useAnalytics()
  const path = sku.includes('|') ? 'bundles' : 'products'
  const url = `/${path}/${slug}/`

  const { image } = useProductMainImage({ sku, slug })

  return (
    <Flex
      sx={{
        flexDirection: ['column', 'row'],
        marginBottom: ['12px', '20px'],
        marginTop: ['12px', '20px'],
        justifyContent: 'space-between'
      }}
    >
      <Flex
        sx={{
          flexDirection: ['row']
        }}
      >
        {image && (
          <Box
            sx={{
              minWidth: ['96px', '144px']
            }}
          >
            <Link
              to={url}
              onClick={() => trackProductClicked({ productHandle: slug })}
            >
              <Image
                src={variantImage.originalSrc}
                alt={image.title}
                sx={{ maxWidth: [96, 144, 144] }}
              />
            </Link>
          </Box>
        )}
        <Flex
          sx={{
            flexDirection: 'column',
            flexGrow: 1,
            height: '100%',
            marginLeft: '1rem'
          }}
        >
          <Text
            sx={{
              marginBottom: '0.5rem',
              variant: 'text.h4'
            }}
          >
            {name}
          </Text>
          <Text
            sx={{
              marginBottom: '0.5rem',
              variant: 'text.h4'
            }}
          >
            {options}
          </Text>

          <Text variant="body">{singleDisplayAmount}</Text>
        </Flex>
      </Flex>
      <Flex
        sx={{
          flexDirection: ['row', 'column'],
          alignItems: ['center', 'flex-end'],
          justifyContent: 'space-between',
          height: ['48px', '160px'],
          marginTop: ['12px', '0']
        }}
      >
        {orderConfirmed && <Text>{quantity}</Text>}

        <Text variant="body">{displayAmount}</Text>

        {!orderConfirmed && (
          <QuantitySelector
            quantity={quantity}
            lineItemId={id}
            sx={{
              marginRight: ['0', null, '4px'],
              width: ['96px', '128px']
            }}
          />
        )}
      </Flex>
    </Flex>
  )
}

LineItem.propTypes = {
  id: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string,
  quantity: PropTypes.number,
  displayAmount: PropTypes.string,
  singleDisplayAmount: PropTypes.string,
  orderConfirmed: PropTypes.bool
}

export default LineItem
