import { selectors } from '@chordcommerce/gatsby-theme-performance'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const useProductMainImage = ({ slug, sku }) => {
  const { getAllProducts } = selectors
  const products = useSelector(getAllProducts)

  const [image, setImage] = useState(null)

  useEffect(() => {
    const product = products.find(
      p => p.slug === slug || p.variants.some(v => v.sku === sku)
    )

    if (!product) return

    const image = product.variants.find(v => v.sku === sku).mainImage

    if (image) setImage(image)
  }, [products, sku, slug])

  return { image }
}

export default useProductMainImage
