import PropTypes from 'prop-types'
import React from 'react'
import { Flex, Heading, Text } from 'theme-ui'
import CheckoutButton from '~/components/Cart/CheckoutButton'
import PromoCode from '~/components/Cart/PromoCode'
import GiftCard from '~/components/Cart/GiftCard'
import { useTranslate } from '@chordcommerce/gatsby-theme-performance'
import AppliedGiftCards from '~/components/Cart/AppliedGiftCards'

export const RowWrapper = ({ children }) => (
  <Flex
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      textAlign: 'left',
      my: '.75rem'
    }}
  >
    {children}
  </Flex>
)

const CartSummary = ({
  displayItemTotal,
  displayTaxTotal,
  displayShipTotal,
  displayTotal,
  promotions,
  orderConfirmed = false,
  eligibleForFreeShipping = false
}) => {
  const translate = useTranslate()

  const promoCode = promotions ? promotions.find(p => p.code) : null

  return (
    <Flex
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      <Heading
        as="h3"
        variant="h3"
        sx={{
          marginBottom: ['0.5rem', null, '2rem'],
          textAlign: ['center', null, 'left']
        }}
      >
        {translate('cart.order_summary')}
      </Heading>

      {!orderConfirmed && <PromoCode promotion={promoCode} />}

      {!orderConfirmed && <GiftCard />}

      {displayItemTotal && (
        <RowWrapper>
          <Text>{translate('cart.subtotal')}</Text>
          <Text>{displayItemTotal}</Text>
        </RowWrapper>
      )}

      {promotions.map(({ id, label, displayAmount }) => {
        return (
          <RowWrapper key={id}>
            <Text>{label}</Text>
            <Text>{displayAmount}</Text>
          </RowWrapper>
        )
      })}

      {displayTaxTotal && (
        <RowWrapper>
          <Text>{translate('cart.tax')}</Text>
          <Text>{displayTaxTotal}</Text>
        </RowWrapper>
      )}

      {displayShipTotal && (
        <RowWrapper>
          <Text>
            {orderConfirmed || eligibleForFreeShipping
              ? translate('cart.shipping')
              : translate('cart.calculated_at_checkout')}
          </Text>
          <Text>
            {eligibleForFreeShipping
              ? translate('cart.free_shipping')
              : displayShipTotal}
          </Text>
        </RowWrapper>
      )}

      <AppliedGiftCards />

      {displayTotal && (
        <RowWrapper>
          {!orderConfirmed && <Text>{translate('cart.estimated_total')}</Text>}
          {orderConfirmed && <Text>{translate('cart.total')}</Text>}
          <Text>{displayTotal}</Text>
        </RowWrapper>
      )}

      {!orderConfirmed && <CheckoutButton />}
    </Flex>
  )
}

CartSummary.propTypes = {
  displayItemTotal: PropTypes.string,
  displayTaxTotal: PropTypes.string,
  displayShipTotal: PropTypes.string,
  displayTotal: PropTypes.string,
  promotions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      displayAmount: PropTypes.string
    })
  ),
  orderConfirmed: PropTypes.bool
}

export default CartSummary
