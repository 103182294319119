/** @jsx jsx */
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Button, Flex, jsx, Spinner, Text } from 'theme-ui'
import FormInputWithLabel from '~/components/Generic/Form/InputWithLabel'
import { useCart, useTranslate } from '@chordcommerce/gatsby-theme-performance'

const GiftCard = () => {
  const translate = useTranslate()
  const { cart, applyGiftCard } = useCart()

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      giftCardCode: '' // todo handle cases where gift cards are applied
    }
  })

  const { isFetching } = cart.giftCard
  const [apiError, setApiError] = useState(null)
  const [isFormActive, setIsFormActive] = useState(false)

  const onSubmit = async (data, e) => {
    try {
      setApiError(null)
      await applyGiftCard(data.giftCardCode)
    } catch (error) {
      setApiError(error)
    }
  }

  const activateGiftCardForm = event => {
    if (event) event.preventDefault()
    setIsFormActive(true)
  }

  if (!isFormActive) {
    return (
      <Flex sx={{ flexDirection: 'column', my: '1rem' }}>
        <Box as="form" onSubmit={e => activateGiftCardForm(e)}>
          <Button sx={{ width: '100%' }}>
            <Text>{translate('cart.apply_gift_card')}</Text>
          </Button>
        </Box>
      </Flex>
    )
  }

  return (
    <Flex sx={{ flexDirection: 'column', my: '1rem' }}>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <Flex
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Box sx={{ width: '80%', marginRight: '1rem' }}>
            <FormInputWithLabel
              label={translate('cart.gift_card')}
              title={translate('cart.gift_card')}
              aria-label={translate('cart.gift_card')}
              placeholder={translate('cart.gift_card')}
              name="giftCardCode"
              disabled={false} // todo think through this case
              ref={register({ required: true })}
            />
          </Box>
          <Button>
            {isFetching && <Spinner size="15" color="inherit" />}
            {!isFetching && (
              <Text>{translate('cart.apply_gift_card_button')}</Text>
            )}
          </Button>
        </Flex>
      </Box>
      {apiError && (
        <Text
          variant="formError"
          sx={{
            color: 'errorDark',
            backgroundColor: 'errorLight',
            px: '16px',
            py: '8px'
          }}
        >
          {apiError.message}
        </Text>
      )}
      {errors.promoCode && (
        <Text
          variant="formError"
          sx={{
            color: 'errorDark',
            backgroundColor: 'errorLight',
            px: '16px',
            py: '8px'
          }}
        >
          {translate('validation.required_field')}
        </Text>
      )}
    </Flex>
  )
}

export default GiftCard
