/** @jsx jsx */
import {
  logSentryError,
  selectors,
  useAnalytics,
  useCart,
  useTranslate,
  utils
} from '@chordcommerce/gatsby-theme-performance'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button, Container, Flex, Heading, jsx, Spinner } from 'theme-ui'
import LineItems from '~/components/Cart/LineItems'
import CartSummary from '~/components/Cart/Summary'

const CartContainer = ({ children }) => {
  const translate = useTranslate()

  return (
    <Container>
      <Flex
        sx={{
          flexDirection: 'column',
          marginBottom: ['1.5rem', null, '4.5rem'],
          marginTop: ['0', null, '42px']
        }}
      >
        <Heading
          as="h1"
          sx={{
            variant: 'responsive.desktop',
            lineHeight: '1em',
            textAlign: 'left',
            paddingBottom: '1rem',
            marginBottom: '16px',
            marginLeft: '32px'
          }}
        >
          {translate('cart.title')}
        </Heading>
        {children}
      </Flex>
    </Container>
  )
}

const CartLoading = () => {
  return (
    <CartContainer>
      <Box
        sx={{
          textAlign: 'center',
          width: '100%',
          backgroundColor: 'white',
          padding: ['10rem 1.25rem', '12rem 1.25rem'],
          marginRight: [null, '1rem'],
          marginBottom: ['1rem', null]
        }}
      >
        <Spinner size="50" />
      </Box>
    </CartContainer>
  )
}

const CartEmpty = () => {
  const translate = useTranslate()

  return (
    <CartContainer>
      <Box
        sx={{
          textAlign: 'center',
          width: '100%',
          backgroundColor: 'white',
          padding: ['10rem 1.25rem', '12rem 1.25rem'],
          marginRight: [null, '1rem'],
          marginBottom: ['1rem', null]
        }}
      >
        <Heading as="h4" mb="2rem" mt="0.5rem">
          {translate('cart.empty')}
        </Heading>
        <Button as={Link} to="/shop">
          {translate('cart.shop_button')}
        </Button>
      </Box>
    </CartContainer>
  )
}

const CartNotEmpty = ({
  lineItems,
  displayTotal,
  displayItemTotal,
  displayTaxTotal,
  displayShipTotal,
  promotions,
  eligibleForFreeShipping,
  amountNeededForFreeShipping
}) => {
  return (
    <CartContainer>
      <Flex
        sx={{
          flexDirection: ['column', null, 'row'],
          justifyContent: 'space-around'
        }}
      >
        <Flex
          sx={{
            flexGrow: 1,
            height: '100%',
            maxWidth: [null, null, '60%'],
            backgroundColor: 'white',
            padding: ['24px 16px', null, '42px 36px'],
            marginRight: [null, null, '1rem'],
            marginBottom: '1rem'
          }}
        >
          <LineItems
            items={lineItems}
            amountNeededForFreeShipping={amountNeededForFreeShipping}
            eligibleForFreeShipping={eligibleForFreeShipping}
          />
        </Flex>
        <Flex
          sx={{
            flexGrow: 1,
            height: '100%',
            backgroundColor: 'white',
            padding: ['16px', null, '36px']
          }}
        >
          <CartSummary
            displayItemTotal={displayItemTotal}
            displayTaxTotal={displayTaxTotal}
            displayShipTotal={displayShipTotal}
            displayTotal={displayTotal}
            promotions={promotions}
            eligibleForFreeShipping={eligibleForFreeShipping}
          />
        </Flex>
      </Flex>
    </CartContainer>
  )
}

const CartPage = () => {
  const { cart, loadCart } = useCart()
  const { trackCartViewed } = useAnalytics()
  const { getAmountNeededForFreeShipping } = selectors
  const { getAllCartPromotionsForDisplay } = utils

  useEffect(() => {
    const loadAndTrackCart = async () => {
      try {
        await loadCart()
        trackCartViewed()
      } catch (error) {
        logSentryError(error, { source: 'Cart' })
      }
    }

    loadAndTrackCart()
  }, [loadCart, trackCartViewed])

  const amountNeededForFreeShipping = useSelector(
    getAmountNeededForFreeShipping
  )
  const eligibleForFreeShipping = amountNeededForFreeShipping === 0
  const { isFetching, data: order } = cart

  if (isFetching && order.lineItems && order.lineItems.length === 0)
    return <CartLoading />
  if (!order.lineItems || order.lineItems.length === 0) return <CartEmpty />

  const formatCurrency = value => `$${(+value).toFixed(2)}`

  const giftCardsAmountUsed = order.appliedGiftCards.reduce(
    (acc, cur) => acc + +cur.amountUsedV2.amount,
    0
  )

  const displayItemTotal = formatCurrency(order.lineItemsSubtotalPrice.amount)
  const displayTaxTotal = formatCurrency(order.totalTax)
  const displayShipTotal = formatCurrency('0.00')
  const displayTotal = formatCurrency(order.totalPrice - giftCardsAmountUsed)
  const { lineItems } = order

  const promotions = getAllCartPromotionsForDisplay(order)

  return (
    <CartNotEmpty
      lineItems={lineItems}
      displayTotal={displayTotal}
      displayItemTotal={displayItemTotal}
      displayTaxTotal={displayTaxTotal}
      displayShipTotal={displayShipTotal}
      promotions={promotions}
      amountNeededForFreeShipping={amountNeededForFreeShipping}
      eligibleForFreeShipping={eligibleForFreeShipping}
    />
  )
}

CartNotEmpty.propTypes = {
  lineItems: PropTypes.arrayOf(PropTypes.shape({})),
  displayTotal: PropTypes.string,
  displayItemTotal: PropTypes.string,
  displayTaxTotal: PropTypes.string,
  displayShipTotal: PropTypes.string,
  promotions: PropTypes.arrayOf(PropTypes.shape({}))
}

export default CartPage
