/** @jsx jsx */
import { useState } from 'react'
import {
  logSentryError,
  useCart,
  useTranslate
} from '@chordcommerce/gatsby-theme-performance'
import { Button, Text, Spinner, jsx } from 'theme-ui'

const CheckoutButton = () => {
  const { checkout } = useCart()
  const translate = useTranslate()
  const [buttonIsLoading, setButtonIsLoading] = useState(false)
  const [apiError, setApiError] = useState(null)

  const handleSubmit = async event => {
    if (event) event.preventDefault()

    setButtonIsLoading(true)
    setApiError(null)

    try {
      await checkout()
    } catch (error) {
      setApiError(translate('error.api.default'))
      logSentryError(error, { source: 'CheckoutButton' })
    }

    setButtonIsLoading(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Button
        sx={{
          marginTop: '1rem',
          width: '100%'
        }}
      >
        {buttonIsLoading && (
          <Spinner data-testid="spinner" size="15" color="inherit" />
        )}

        {!buttonIsLoading && (
          <Text variant="link">{translate('cart.checkout')}</Text>
        )}
      </Button>

      {apiError && (
        <Text color="errorDark" variant="textLink" mt="1.5rem">
          {apiError}
        </Text>
      )}
    </form>
  )
}

export default CheckoutButton
