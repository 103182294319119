import React from 'react'
import CartPage from '~/components/Cart/Page'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import { useTranslate } from '@chordcommerce/gatsby-theme-performance'

const Cart = () => {
  const translate = useTranslate()

  return (
    <Layout>
      <Metadata title={translate('cart.page_title')} />
      <CartPage />
    </Layout>
  )
}

export default Cart
